// 运行时配置

import Footer from '@/components/Footer';
import RightContent from '@/components/RightContent/index';
import {
  Link,
  RunTimeLayoutConfig,
  history,
  useKeepOutlets,
  useSelectedRoutes,
} from '@umijs/max';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import React from 'react';
import { RequestConfig } from 'umi';
import './assets/css/common.less'; //公共css样式
// import './assets/fonts/iconfont.css'; //iconfont css
import './assets/fonts/iconfont.js'; //iconfont js
import ErrorBoundary from './components/ErrorBoundary';
import PageContext from './context/pageContext'; //用户信息上下文
import FieldContext from './context/rootConetext'; //用户信息上下文
import {
  TokRequestInterceptors,
  TokResponseInterceptor,
  tok_access
} from './utils/HttpUtil';
import { forIn } from 'lodash';

dayjs.locale('zh-cn');

let primaryColor = '#FF9900';
if (process.env.REACT_APP_CONFIG_ENV === 'test') {
  primaryColor = 'red';
} else if (process.env.REACT_APP_CONFIG_ENV === 'dev') {
  primaryColor = 'green';
} else if (process.env.REACT_APP_CONFIG_ENV === 'prod') {
  primaryColor = '#1890ff';
}

const user = localStorage.getItem(
  process.env.REACT_APP_CONFIG_ENV + '_account_user_1',
);

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate

let _pageroutes = [];

// const data = [
//   {
//     id: '1',
//     name: '财务中心',
//     allowshow: true,
//     children: [
//       {
//         id: '11',
//         name: '订单管理',
//         allowshow: true,
//         allowfunc: {
//           导出: false,
//           搜索: false,
//         },
//         children: [
//           {
//             id: '111',
//             name: '订单详情',
//             allowshow: false,
//             children: [],
//           },
//         ],
//       },
//       {
//         id: '12',
//         name: '账簿管理',
//         allowshow: true,
//         allowfunc: {
//           导出: false,
//           搜索: false,
//         },
//         children: [
//           {
//             id: '121',
//             name: '账簿管理详情',
//             allowshow: false,
//           },
//         ],
//       },
//       {
//         id: '13',
//         name: '对账单',
//         allowshow: true,
//         allowfunc: {
//           导出: false,
//           搜索: false,
//         },
//         children: [
//           {
//             id: '131',
//             name: '对账单详情',
//             allowshow: false,
//           },
//           {
//             id: '132',
//             name: '生成对账单',
//             allowshow: false,
//           },
//           {
//             id: '133',
//             name: '新建对账单',
//             allowshow: false,
//           },
//         ],
//       },
//       {
//         id: '14',
//         name: '结算管理',
//         allowshow: true,
//         allowfunc: {
//           导出: false,
//           搜索: false,
//         },
//         children: [
//           {
//             id: '141',
//             name: '结算管理详情',
//             allowshow: false,
//           },
//         ],
//       },
//       {
//         id: '15',
//         name: '收付款管理',
//         allowshow: true,
//         allowfunc: {
//           导出: false,
//           搜索: false,
//         },
//         children: [
//           {
//             id: '151',
//             name: '新建收付款',
//             allowshow: false,
//           },
//           {
//             id: '152',
//             name: '收付款详情',
//             allowshow: false,
//           },
//           {
//             id: '153',
//             name: '查看收付款记录',
//             allowshow: false,
//           },
//         ],
//       },
//     ],
//   },
// ];

const flatData = (_data, parent, outdata = []) => {
  if (!_data) return []
  // console.log(_data, '============_data_data_data');
  for (let i = 0; i < _data.length; i++) {
    const element = _data[i];
    const children = _data[i].child_sys_menu.filter(item => item.menu_type === 'F')
    const data = {
      id: element.menu_id,
      parent: element.parent_menu_id,
      name: element.menu_name,
      // data: element.allowfunc,
      access: element.visible,
      menu_type: element.menu_type,
      children
    };

    outdata.push(data);
    if (element?.child_sys_menu?.length) {
      flatData(element.child_sys_menu, element.name, outdata);
    }
  }
  return outdata;
};

const handlePath = (routes, handellist) => {
  const _handellist = handellist;
  for (let i = 0; i < routes.length; i++) {
    const element = routes[i];
    if (element.name) {
      let _i;
      const _obj = _handellist.find((item, index) => {
        if (item.name === element.name) {
          _i = index;
        }
        return item.name === element.name;
      });
      if (!_obj) {
        routes.splice(i, 1);
        i--;
        if (_i !== undefined) {
          _handellist.splice(i, 1);
        }
        continue;
      }
      // if (_obj && !_obj.access) {
      //   console.log('删除了路由=========>', routes[i]);
      //   routes.splice(i, 1);
      //   i--;
      //   if (_i !== undefined) {
      //     console.log('删除了权限列表=========>', _handellist[_i]);
      //     _handellist.splice(i, 1);
      //   }
      //   continue;
      // }
    }
    if (element?.children?.length) {
      handlePath(element.children, _handellist);
    }
  }
  return routes;
};

export const layout: RunTimeLayoutConfig = () => {
  return {
    logo: '/logo.svg',
    iconfontUrl: '/fonts/iconfont.js',
    menu: {
      locale: false,
    },
    token: {
      sider: {
        colorMenuBackground: '#fff',
        colorBgMenuItemHover: '#FFF2F0',
        colorBgMenuItemCollapsedHover: '#FFF2F0',
        colorBgMenuItemSelected: '#FFF2F0',
        colorTextMenu: '#001529',
        colorTextMenuSelected: '#FF4D4F',
        colorTextMenuActive: '#001529',
        colorTextMenuSecondary: '#001529',
        colorTextMenuItemHover: '#FF4D4F',
      },
      header: {
        colorBgHeader: '#fff',
        colorHeaderTitle: '#001529',
      },
    },
    layout: 'mix',
    fixedHeader: false,
    fixSiderbar: true,
    locale: 'zh-CN',
    contentWidth: 'Fluid',
    title: '步甲科技财务系统',
    settings: {
      colorPrimary: primaryColor,
      pwa: false,
      colorWeak: false,
    },
    menuDataRender: (menuData) => {
      const ff = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_CONFIG_ENV + '_finance_access'))
      const dd = flatData(ff);
      handlePath(menuData, dd)
      return menuData
    },
    menuItemRender: (menuItemProps, defaultDom, menuProps) => {
      if (
        menuItemProps.isUrl ||
        menuItemProps.children ||
        !menuItemProps.path
      ) {
        return defaultDom;
      }
      return <Link to={menuItemProps.path}>{defaultDom}</Link>;
    },
    childrenRender: (dom, props) => {
      console.log(dom, props);
      const ff = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_CONFIG_ENV + '_finance_access'))
      const datalist = useSelectedRoutes();
      const data1 = datalist[datalist.length - 1];
      const dd = flatData(ff);
      const obj = dd.find((item) => item.name === data1.route.name);
      let _obj = {}
      obj?.children.map(item => {
        _obj[item.menu_id] = { menu_id: item.menu_id, visible: item.visible, name: item.menu_name }
      })
      //渲染多标签 keep-alive
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const element = useKeepOutlets();
      const element1 = React.createElement(
        PageContext.Provider,
        { value: { access: _obj } },
        element,
      );
      return element1;
    },
    rightContentRender: () => RightContent(),
    footerRender: () => Footer(),
    ErrorComponent: <ErrorBoundary></ErrorBoundary>,
  };
};
// export const onRouteChange = (e) => {
//   console.log(e, '=========eeee');

// }
// export const patchClientRoutes = ({ routes }) => {
//   console.log(routes, '=========eeee');
//   routes[1].routes.splice(1, 1);
// }
export function rootContainer(container: React.ReactElement) {
  return React.createElement(
    FieldContext.Provider,
    { value: { user: user && (JSON.parse(user) as any) } },
    container,
  );
}

export async function getInitialState(): Promise<any> {
  let currentUser = localStorage.getItem(
    process.env.REACT_APP_CONFIG_ENV + '_account_user_1',
  );

  tok_access({ uid: '' }).then(async (res: any) => {
    if (res?.ret_info?.ret === 0) {
      sessionStorage.setItem(process.env.REACT_APP_CONFIG_ENV + '_finance_access', JSON.stringify(res?.sys_menu))
    }
  })

  if (!currentUser) {
    history.replace({
      pathname: '/user/login' + location.search,
    });
  }

  return {
    currentUser: currentUser && JSON.parse(currentUser),
  };
}

// const codeMessage = {
//   200: '服务器成功返回请求的数据。',
//   201: '新建或修改数据成功。',
//   202: '一个请求已经进入后台排队（异步任务）。',
//   204: '删除数据成功。',
//   400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
//   401: '用户没有权限（令牌、用户名、密码错误）。',
//   403: '用户得到授权，但是访问是被禁止的。',
//   404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
//   405: '请求方法不被允许。',
//   406: '请求的格式不可得。',
//   410: '请求的资源被永久删除，且不会再得到的。',
//   422: '当创建一个对象时，发生一个验证错误。',
//   500: '服务器发生错误，请检查服务器。',
//   502: '网关错误。',
//   503: '服务不可用，服务器暂时过载或维护。',
//   504: '网关超时。',
// } as any;

/**
 * 异常处理程序
 */
// const errorHandler = (error: ResponseError) => {
//   const { response } = error;
//   if (response && response.status) {
//     const errorText = codeMessage[response.status] || response.statusText;
//     const { status, url } = response;

//     notification.error({
//       message: `请求错误 ${status}: ${url}`,
//       description: errorText,
//     });
//   }

//   if (!response) {
//     notification.error({
//       description: '您的网络发生异常，无法连接服务器',
//       message: '网络异常',
//     });
//   }
//   throw error;
// };

export const request: RequestConfig = {
  errorConfig: {
    // errorHandler:errorHandler,
  },
  requestInterceptors: [TokRequestInterceptors],
  responseInterceptors: [TokResponseInterceptor as any],
};
