import { createFromIconfontCN } from '@ant-design/icons';

export const removeBaidu = (
  times: number = 5,
  looptimes: number = 1000,
): void => {
  const baiduDom = document.getElementById('baidu_jsapi_watermark');
  if (baiduDom) {
    baiduDom.remove();
  } else {
    if (times) {
      setTimeout(() => {
        // eslint-disable-next-line no-param-reassign
        removeBaidu(--times);
      }, looptimes);
    }
  }
};

export const analysisLocationSearch = (searchstr: string): any => {
  if (!searchstr) return {};
  const strArr = searchstr.slice(1).split('&');
  const obj: any = {};
  for (const key in strArr) {
    if (Object.hasOwn(strArr, key)) {
      const tmpArr = strArr[key].split('=');
      obj[tmpArr[0]] = obj[tmpArr[1]];
    }
  }
  return obj;
};

const file_size_match: any = {
  b: 0,
  kb: 1,
  mb: 2,
  gb: 3,
  tb: 4,
};

export const convent_FileSizeToNumber = (size: string): number => {
  if (!size) return -1;
  let _size = size + '';
  const index = [..._size.matchAll(/\d/g)].length;
  const str = _size.slice(index);
  const num = Number(_size.slice(0, index));
  return str
    ? num * Math.pow(1024, file_size_match[str.toLocaleLowerCase()])
    : num;
};

export const convent_NumberToFileSize = (num: number): string => {
  if (!num) return '';
  let _num = num;
  let times = 0;
  while (_num >= 1024) {
    _num = _num / 1024;
    times++;
  }
  let _key = '';
  for (const key in file_size_match) {
    if (file_size_match[key] === times) {
      _key = key;
      break;
    }
  }
  return (Number(_num.toFixed(2)) + _key).toLocaleUpperCase();
};

//iconfont
export const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_2673781_6srk40yjg9m.js',
});
// 小写转中文大写
export const chineseNumber = (n: any) => {
  let fraction = ['角', '分'];
  let digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  let unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟'],
  ];
  let head = n < 0 ? '负' : '';
  n = Math.abs(n);
  let s = '';
  for (let i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, '元')
      .replace(/(零.)+/g, '零')
      .replace(/^整$/, '零元整')
  );
};
