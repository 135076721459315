import React from 'react';
import { DefaultFooter } from '@ant-design/pro-layout';

export default () => (
  <DefaultFooter
    copyright="2023 步甲科技出品"
    links={[
    ]}
  />
);
