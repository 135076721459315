import { Button } from 'antd';
import React from 'react';
// import { history } from "umi";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    console.log(error, errorInfo);
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.hasError) {
      // Error path
      return (
        <div
          style={{
            width: '100%',
            height: document.body.offsetHeight + 'px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#fff',
          }}
        >
          <img
            style={{ marginBottom: '20px' }}
            src={require('@/assets/image/404.jpeg')}
            alt=""
          />
          <h4 style={{ marginBottom: '20px', fontSize: '24px' }}>
            页面出错啦！
          </h4>
          <div>
            {/* <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                window.history.back();
              }}
            >
              返回上一级
            </Button> */}
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => {
                open('/', '_self');
              }}
            >
              返回首页
            </Button>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
