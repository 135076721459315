import { login } from '@/pages/user/login/service';
import { analysisLocationSearch } from '@/utils/tools';
import { message } from 'antd';
import { stringify } from 'querystring';
import { Effect, Reducer, Subscription, history } from 'umi';

export interface UserModelState {
  // id: number | string;
  // name: string;
  // tokUser: any;
  isLogin: boolean;
  userInfo: any;
}

export interface DispatchProps {
  login: (params: any) => void;
  logout: () => void;
  isLogin: boolean;
  userInfo: any;
}

export interface UserModelType {
  namespace: string;
  state: UserModelState;
  effects: {
    login: Effect;
    logout: Effect;
  };
  reducers: {
    // 启用 immer
    save: Reducer<UserModelState>;
  };
  subscriptions: { setup: Subscription };
}

/**
 * 此方法会跳转到 redirect 参数所在的位置
 */
const goto = () => {
  console.log(history);

  const { search } = history.location;
  const { redirect } = analysisLocationSearch(search) as { redirect: string };
  window.location.href = redirect || '/';
};

export default {
  namespace: 'userInfo',
  state: {
    isLogin: false,
    userInfo: {},
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: {
    *login({ payload }, { call, put }): any {
      console.log(payload);
      let res = yield call(login, payload);
      console.log(res);
      let data = {
        userInfo: {},
        isLogin: false,
      };
      if (res?.ret_info?.msg === '登陆成功') {
        console.log(res, '------------res');

        data.userInfo = {
          name: res.account,
          avatar:
            'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
          // userid: "00000001",
          access: res.role,
          headImgUrl: res.tokUser.headImgUrl,
          uid: res.tokUser.uid,
          nick: res.tokUser.userNick,
          phone: res.tokUser.userMobile,
        };
        data.isLogin = true;
        localStorage.setItem(
          process.env.REACT_APP_CONFIG_ENV + '_account_ticket',
          JSON.stringify({
            appId: res.webTicket.appId,
            c2: res.webTicket.c2,
            c3: res.webTicket.c3,
            c3Key: res.webTicket.c3Key,
            c3Iv: res.webTicket.c3Iv,
            expireTs: res.webTicket.expireTs,
          }),
        );
        localStorage.setItem(
          process.env.REACT_APP_CONFIG_ENV + '_account_user_1',
          JSON.stringify(data.userInfo),
        );
        message.success('登录成功！');
        location.href = '/';
        // goto();
      } else {
        data.userInfo = {};
        data.isLogin = false;
        message.error('登录失败，请重试！');
      }
      yield put({ type: 'save', payload: data });
    },
    *logout(_, { call, put }) {
      localStorage.removeItem(
        process.env.REACT_APP_CONFIG_ENV + '_account_user_1',
      );
      localStorage.removeItem(
        process.env.REACT_APP_CONFIG_ENV + '_account_ticket',
      );
      sessionStorage.removeItem(
        process.env.REACT_APP_CONFIG_ENV + '_finance_access',
      );
      const { search, pathname } = history.location;
      const { redirect } = analysisLocationSearch(search);
      // Note: There may be security issues, please note
      if (window.location.pathname !== '/user/login' && !redirect) {
        history.replace({
          pathname: '/user/login',
          search: stringify({
            redirect: pathname,
          }),
        });
      }
      let payload = {
        userInfo: {},
        isLogin: false,
      };
      yield put({ type: 'save', payload });
    },
  },
  subscriptions: {
    setup({ dispatch, history }) {
      console.log(history);
      const user = JSON.parse(
        localStorage.getItem(
          process.env.REACT_APP_CONFIG_ENV + '_account_user_1',
        ) as string,
      );
      const payload = {
        userInfo: {},
        isLogin: false,
      };
      if (user) {
        payload.userInfo = user;
        payload.isLogin = true;
      } else {
        payload.userInfo = {};
        payload.isLogin = false;
      }
      dispatch({ type: 'save', payload });
    },
  },
} as UserModelType;
