import React, { useCallback } from 'react';
import {
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Menu, Spin } from 'antd';
import { connect, history, useModel } from 'umi';
// import { outLogin } from "@/services/login";
// import { stringify } from "querystring";
import { DispatchProps } from '@/models/user';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';

export interface GlobalHeaderRightProps {
  menu?: boolean;
}

const namespace = 'userInfo';

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => {
      dispatch({ type: namespace + '/logout' });
    },
  };
};

type Props = GlobalHeaderRightProps & DispatchProps;

const AvatarDropdown: React.FC<Props> = ({ menu, ...props }) => {
  const { initialState, setInitialState } = useModel('@@initialState');

  console.log(initialState, menu);

  /**
   * 退出登录，并且将当前的 url 保存
   */
  const loginOut = async () => {
    props.logout();
  };

  const onMenuClick = useCallback(
    (event: {
      key: React.Key;
      keyPath: React.Key[];
      item: React.ReactInstance;
      domEvent: React.MouseEvent<HTMLElement>;
    }) => {
      const { key } = event;
      if (key === 'logout' && initialState) {
        setInitialState({ ...initialState, currentUser: undefined });
        loginOut();
        return;
      }
      history.push(`/account/${key}`);
    },
    [],
  );

  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  console.log(initialState, '===============initialState');

  if (!initialState) {
    return loading;
  }

  const { currentUser } = initialState;

  if (!currentUser || !currentUser.name) {
    return loading;
  }

  const menuHeaderDropdown = (
    <Menu
      className={styles.menu}
      selectedKeys={[]}
      items={[
        {
          key: 'logout',
          icon: <LogoutOutlined />,
          title: '退出登录',
          label: '退出登录',
        },
      ]}
      onClick={onMenuClick}
    >
      {/* {menu && (
        <Menu.Item key="center">
          <UserOutlined />
          个人中心
        </Menu.Item>
      )}
      {menu && (
        <Menu.Item key="settings">
          <SettingOutlined />
          个人设置
        </Menu.Item>
      )}
      {menu && <Menu.Divider />}

      <Menu.Item key="logout">
        <LogoutOutlined />
        退出登录
      </Menu.Item> */}
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <Avatar
          style={{ backgroundColor: '#1890ff', marginRight: 5 }}
          size="small"
          icon={<UserOutlined />}
        />
        <span className={`${styles.name} anticon`} style={{ color: '#061629' }}>
          {currentUser.name}
        </span>
      </span>
    </HeaderDropdown>
  );
};

export default connect(null, mapDispatchToProps)(AvatarDropdown);
