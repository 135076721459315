import { PageLoading } from '@ant-design/pro-components';
import React, { Component } from 'react';

class Loading extends Component {
  render() {
    return <PageLoading />;
  }
}

export default Loading;
