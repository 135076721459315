export default (initialState: API.UserInfo) => {
  // 在这里按照初始化数据定义项目中的权限，统一管理
  // 参考文档 https://umijs.org/docs/max/access

  console.log('==========================>accessStart');
  console.log(initialState);
  console.log('==========================>accessEnd');

  const canSeeAdmin = !!(
    initialState && initialState.name !== 'dontHaveAccess'
  );
  return {
    canSeeAdmin,
    pageAccess: initialState?.userAccess,
  };
};
